import React, { useState } from "react";
import { useForm } from "react-hook-form";
import WebcamComponent from "./Webcam";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const AdmissionForm = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [webCamEnabled, setWebCamEnabled] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero to month
    const day = String(today.getDate()).padStart(2, "0"); // Add leading zero to day
    return `${year}-${month}-${day}`;
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      studentId: "",
      studentName: "",
      fatherName: "",
      motherName: "",
      guardianNumber: "",
      gender: "",
      number: "",
      email: "",
      dob: "",
      aadharNumber: "",
      city: "",
      state: "",
      address: "",
      degree: "",
      collegeName: "",
      passingYear: "",
      grade: "",
      courseName: "",
      duration: "",
      batchTiming: "",
      facultyName: "",
      admissionDate: getCurrentDate(),
      profileImage: "",
    },
  });

  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (!imageSrc) {
      toast.error("Please capture image");
      return;
    }

    // Confirm email before submitting the form
    const { value: emailConfirmed } = await Swal.fire({
      title: "Confirm your email",
      text: `Is this your correct email: ${data.email}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      cancelButtonText: "No, cancel",
    });

    if (!emailConfirmed) {
      toast.error("Please confirm your email before submitting");
      return;
    }

    try {
      console.log(data);
      // let newData = {...data}

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key]);
        }
      }

      const base64Data = data.profileImage.split(",")[1]; // Only the Base64 data
      const mimeType = data.profileImage.match(/^data:(.*);base64,/)[1]; // Extract MIME type (e.g., image/jpeg)

      const imageBlob = base64ToBlob(base64Data, mimeType);

      const file = new File([imageBlob], `${data.studentName}.jpg`, {
        type: mimeType,
      });

      formData.append("profileImage", file);

      const result = await axios.post(
        "https://api-panel.mindcoders.in/admission/add",
        formData
      );

      toast.success("Admission Form submitted successfully");
      reset();
      setImageSrc(null);
      setWebCamEnabled(false);
    } catch (error) {
      console.error(error);
      toast.error("Error submitting admission form");
    }
  };

  const handleImageCapture = (image) => {
    setValue("profileImage", image);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Personal Information Section */}
        <section className="mb-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            PERSONAL INFORMATION
          </h2>
          <div className="flex flex-col md:flex-row justify-around gap-10">
            <div className="grid order-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:w-[70%] gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Student Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("studentName", { required: true })}
                />
                {errors.studentName && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Father's Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("fatherName", { required: true })}
                />
                {errors.fatherName && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mother's Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("motherName", { required: true })}
                />
                {errors.motherName && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Guardian No. <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="number"
                  {...register("guardianNumber", { required: true })}
                />
                {errors.guardianNumber && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  {...register("gender", { required: true })}
                >
                  <option value="">-Select Gender-</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Contact No. <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="number"
                  {...register("number", { required: true })}
                />
                {errors.number && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email ID <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="date"
                  {...register("dob", { required: true })}
                />
                {errors.dob && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Aadhar No. <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="number"
                  {...register("aadharNumber", { required: true })}
                />
                {errors.aadharNumber && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  City <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("city", { required: true })}
                />
                {errors.city && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  State <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("state", { required: true })}
                />
                {errors.state && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Permanent Address <span className="text-red-500">*</span>
                </label>
                <textarea
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  rows={1}
                  {...register("address", { required: true })}
                ></textarea>
                {errors.address && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
            </div>
            <div className="space-y-3 order-1 md:w-[30%]">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date of Admission
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="date"
                  {...register("admissionDate", { required: true })}
                />
                {errors.admissionDate && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <WebcamComponent
                getImage={handleImageCapture}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                webCamEnabled={webCamEnabled}
                setWebCamEnabled={setWebCamEnabled}
              />
            </div>
          </div>
        </section>

        {/* Board Success Information Section */}
        <section className="mb-8">
          <div>
            <h2 className="text-xl md:text-2xl font-semibold mb-4">
              QUALIFICATION
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Degree <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("degree", { required: true })}
                  list="degree"
                />
                {errors.degree && (
                  <span className="text-red-600">This field is required</span>
                )}
                <datalist id="degree">
                  <option value="B.Sc.">B.Sc.</option>
                  <option value="B.Tech">B.Tech</option>
                  <option value="M.Sc.">M.Sc.</option>
                  <option value="M.Tech">M.Tech</option>
                  <option value="B.Com">B.Com</option>
                  <option value="M.Com">M.Com</option>
                </datalist>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  College/University Name{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("collegeName", { required: true })}
                />
                {errors.collegeName && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Passing Year <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("passingYear", { required: true })}
                  list="passingYear"
                />
                {errors.passingYear && (
                  <span className="text-red-600">This field is required</span>
                )}
                <datalist id="passingYear">
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </datalist>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Grade <span className="text-red-500">*</span>
                </label>
                <input
                  className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                  type="text"
                  {...register("grade", { required: true })}
                />
                {errors.grade && (
                  <span className="text-red-600">This field is required</span>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Admission Details Section */}
        <section className="mb-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            ADMISSION DETAILS
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Course Name <span className="text-red-500">*</span>
              </label>
              <input
                className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                type="text"
                {...register("courseName", { required: true })}
                list="courseName"
              />
              {errors.courseName && (
                <span className="text-red-600">This field is required</span>
              )}
              <datalist id="courseName">
                <option value="MERN Stack">MERN Stack</option>
                <option value="Frontend">Frontend</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Data Analytics">Data Analytics</option>
              </datalist>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Course Duration <span className="text-red-500">*</span>
              </label>
              <input
                className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                type="text"
                {...register("duration", { required: true })}
                list="duration"
              />
              {errors.duration && (
                <span className="text-red-600">This field is required</span>
              )}
              <datalist id="duration">
                <option value="2 - 3 months">2 - 3 months</option>
                <option value="3 - 4 months">3 - 4 months</option>
                <option value="4 - 5 months">4 - 5 months</option>
                <option value="5 - 6 months">5 - 6 months</option>
              </datalist>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Batch Timing <span className="text-red-500">*</span>
              </label>
              <input
                className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                type="text"
                {...register("batchTiming", { required: true })}
              />
              {errors.batchTiming && (
                <span className="text-red-600">This field is required</span>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Faculty Name <span className="text-red-500">*</span>
              </label>
              <input
                className="mt-1 block w-full border rounded-md p-2 border-gray-300"
                type="text"
                {...register("facultyName", { required: true })}
                list="facultyName"
              />
              {errors.facultyName && (
                <span className="text-red-600">This field is required</span>
              )}
              <datalist id="facultyName">
                <option value="Krishnakant Sir">Krishnakant Sir</option>
                <option value="Shiv Sir">Shiv Sir</option>
                <option value="Himanshu Sir">Himanshu Sir</option>
                <option value="Vinay Sir">Vinay Sir</option>
                <option value="Nameet Sir">Nameet Sir</option>
              </datalist>
            </div>
          </div>
        </section>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default AdmissionForm;
